import { Fragment, useState, useRef } from "react";
import PageTitle from "../../../components/Nav/PageTitle";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useNotifications from "../../../hooks/useNotifications";
import get from 'lodash/get';
import { renderErrors } from "../../../common/formHelpers";
import { breadcrumbs } from "..";
import ContextHelp from "../../../components/ContextHelp";
import Modal from "../../../components/Modal";
import { generateWorkspacePath } from "../../../common/urlHelpers";
import ScriptLanguageSelector from "../../../components/ScriptLanguageSelector";
import { filter } from "lodash";
import ConfigBuilder from "../../../components/ConfigBuilder";
import { mainScriptTemplate, modScriptTemplate } from "../../../common/scripts";

const scriptTemplate = `module.exports = function (event, ctx, config) {
    //add your script here to transform or enrich the event

    //remember to return the transformed event object for the pipeline to continue processing the event
    return event;
}`;


const NewTransformation = () => {
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [name, setName] = useState('');
    const type = 'TRANSFORMATION';
    const [script, setScript] = useState(scriptTemplate);
    const [modScript, setModScript] = useState(modScriptTemplate);
    const [mainScript, setMainScript] = useState(mainScriptTemplate);
    const [active, setActive] = useState(true);
    const [config, setConfig] = useState([{ key: "", value: "", secret: false }]);
    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [expandEditor, setExpandEditor] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('javascript');
    const workspacePath = generateWorkspacePath();

    const resetAll = () => {
        setName('');
        setScript(scriptTemplate);
        setMainScript(mainScriptTemplate);
        setModScript(modScriptTemplate);
        setActive(true);
        clearErrors();
    };

    const clearErrors = () => {
        setError({ message: null, details: [] });
    };

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);

        if (!name) {
            addNotification({
                message: 'Enter Transformation Name',
                type: 'error'
            });
            setExecuting(false);
            return;
        }

        const payload = {
            name,
            active,
            type,
            config: filter(config, item => item.key),
            language: selectedLanguage === 'javascript' ? selectedLanguage : 'golang'
        };

        if (selectedLanguage === "Javascript" || selectedLanguage === "javascript") {
            payload.script = script;
        } else {
            payload.golang = {
                main: mainScript,
                mod: modScript
            }
        }

        axios.post(`${workspacePath}/transformations`, payload).then(response => {
            resetAll();
            addNotification({
                message: 'Transformation has been created',
                type: 'success'
            });
            navigate('/transformations');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while creating Transformation'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        });
    };

    return (
        <Fragment>
            <PageTitle itemKey="transformations" label="New Transformation" breadcrumbs={breadcrumbs} />
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <form onSubmit={submit}>
                        <div className="shadow-inner sm:overflow-hidden sm:rounded-md">
                            <div className="space-y-3 px-4 py-5 sm:p-6">
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Transformation Name</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Provide a name for the Transformation"
                                        value={name}
                                        className="input input-bordered w-full"
                                        onChange={e => setName(e.target.value)}
                                    />
                                    {renderErrors(error, 'name')}
                                </div>

                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Configuration</span>
                                    </label>
                                    <ConfigBuilder config={config} setConfig={setConfig} />
                                    {renderErrors(error, 'config')}
                                </div>

                                <ScriptLanguageSelector
                                    script={script}
                                    setScript={setScript}
                                    mainScript={mainScript}
                                    setMainScript={setMainScript}
                                    modScript={modScript}
                                    setModScript={setModScript}
                                    expandEditor={expandEditor}
                                    setExpandEditor={setExpandEditor}
                                    error={error}
                                    addNotification={addNotification}
                                    workspacePath={workspacePath}
                                    editMode={true}
                                    selectedLanguage={selectedLanguage}
                                    setSelectedLanguage={setSelectedLanguage}
                                />

                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Active</span>
                                    </label>
                                    <input
                                        type="checkbox"
                                        className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`}
                                        checked={active}
                                        onChange={(e) => setActive(e.target.checked)}
                                    />
                                    {renderErrors(error, 'active')}
                                </div>
                            </div>
                            <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                                <Link to="/transformations" className="btn btn-ghost mr-2">Cancel</Link>
                                <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="md:col-span-1">
                    <ContextHelp page="create-transformation" />
                </div>
            </div>
            {expandEditor && (
                <Modal large={true} title="Script" onCancel={() => setExpandEditor(false)}>
                    <>
                        <div className="px-6 pb-4">

                            <ScriptLanguageSelector
                                script={script}
                                setScript={setScript}
                                mainScript={mainScript}
                                setMainScript={setMainScript}
                                modScript={modScript}
                                setModScript={setModScript}
                                expandEditor={expandEditor}
                                setExpandEditor={setExpandEditor}
                                error={error}
                                addNotification={addNotification}
                                workspacePath={workspacePath}
                                editMode={true}
                                selectedLanguage={selectedLanguage}
                                setSelectedLanguage={setSelectedLanguage}

                            />

                        </div>
                        <div className="bg-base-200 px-4 py-3 justify-end sm:px-6 flex">
                            <button className="btn" onClick={() => setExpandEditor(false)}>Collapse</button>
                        </div>
                    </>
                </Modal>
            )}
        </Fragment>
    );
};

export default NewTransformation;
